import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Branding y Diseño',
        description: 'Si necesitas una imagen para tu negocio podemos ayudarte.  Somos especialistas en branding y en rediseño y modernización de marca. Diseñamos logotipos para usar en todos los soportes y le damos a tu negocio la imagen seria y profesional que necesitas.'
    },
    {
        icon: <FiLayers />,
        title: 'Diseño y Mantenimiento Web',
        description: '¿Necesitas una web para tu empresa o comercio? ¿Buscas un buen diseño web y un desarrollo que puedas manejar fácilmente? Estudiamos tus necesidades y objetivos para ofrecerte la web que necesitas para hacer crecer tu negocio.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing online',
        description: 'Conseguir mejorar el posicionamiento de tu página web es uno de los aspectos básicos para crecer. Diseñamos y elaboramos acciones de SEO, campañas SEM de publicidad en internet y mantenimiento de blog y redes sociales.'
    },
    {
        icon: <FiMonitor />,
        title: 'Comunicación',
        description: 'Te ayudamos a crear tu plan de comunicación en redes sociales.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Servicios',
        description = 'Somos una agencia de marketing con servicio completo.  Nos gusta mantener relaciones saludables y felices con nuestros clientes.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/servicios"><span className="text">Todos nuestros servicios</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                  
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
