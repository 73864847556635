import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = 'Somos una agencia de marketing online y desarrollo web especializada en mejorar tu presencia online con el objetivo de ayudarte a captar más clientes';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                                    <h3 className="title">¿Quienes somos?</h3>
                                                    <p>Somos una agencia digital enfocada en marketing online y desarrollo web.  Vivimos en Barcelona y Madrid  y trabajamos con colaboradores y clientes de todo el mundo.</p>
                                                </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                                    <h3 className="title">¿Como Somos?</h3>
                                                    <p>Nos adaptamos a las necesidades de nuestros clientes sea cual sea su tamaño, su sector o sus necesidades. Buscamos ofrecer servicios integrales, que aporten una identidad gráfica sólida y que acompañen a la empresa durante años.</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;