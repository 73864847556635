import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        let title = 'About',
        description = 'Somos una agencia de marketing online y desarrollo web especializada en mejorar tu presencia online con el objetivo de ayudarte a captar más clientes';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                    <h3 className="title">¿Quienes somos?</h3>
                                                    <p>Somos una agencia digital enfocada en marketing online y desarrollo web.  Vivimos en Barcelona y Madrid  y trabajamos con colaboradores y clientes de todo el mundo.</p>
                                                </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div className="about-us-list">
                                                    <h3 className="title">¿Como Somos?</h3>
                                                    <p>Nos adaptamos a las necesidades de nuestros clientes sea cual sea su tamaño, su sector o sus necesidades. Buscamos ofrecer servicios integrales, que aporten una identidad gráfica sólida y que acompañen a la empresa durante años.</p>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                 {/* Start CounterUp Area */}
                 <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h4 className="fontWeight500">Somos un equipo que: creamos, escuchamos y pensamos en como hacer realidad tus ideas y proyectos.</h4>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h5 className="title">Equipo</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/wally.jpg" alt="Equipo Studio Sally"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Wally Torres</h4>
                                        <p className="designation">Digital Producer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.linkedin.com/in/torreswally/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://www.instagram.com/burblemind/"><FaInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/mj.jpg" alt="Equipo Studio Sally"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">María José Massanet</h4>
                                        <p className="designation">Business development & Social media </p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/angela.jpg" alt="Equipo Studio Sally"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Ángela García Ruiz</h4>
                                        <p className="designation">Social media & Communication strategy</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/emilio.jpg" alt="Equipo Studio Sally"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Emilio Obregón</h4>
                                        <p className="designation">Business development & Social media</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.linkedin.com/in/emilioobregon/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/daniss.jpg" alt="Equipo Studio Sally"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Daniela Bustamante</h4>
                                        <p className="designation">Design and Creative</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/palumbo.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Palumbo</h4>
                                        <p className="designation">CEO & cheese addict</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.instagram.com/palumbo_thecat/"><FaInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <h4 className="theme-gradient">NUESTROS SERVICIOS:</h4>
                                <p>Somos una agencia de marketing  con  servicio completo: estrategías multicanal integradas, imagen de marca, desarrollo web,  e-commerce, gestión de redes sociales y posicionamiento web. Nos gusta mantener relaciones saludables y felices con nuestros clientes.</p>
                                <a className="rn-btn btn-white" href="/servicios">Servicios</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/finding-us-01.jpg" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}
                
                {/* Start Testimonial Area */}
                {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div> */}
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About