import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiSmile , FiUsers , FiMonitor ,FiChevronUp,FiPenTool, FiTv } from "react-icons/fi";
import { FaLaptopCode, FaPencilAlt } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import BrandTwo from "../elements/BrandTwo";
import Footer from "../component/footer/Footer";



const ServiceList = [
    {
        icon: <FiPenTool />,
        title: 'Branding',
        description: 'Creamos marcas y las cotamos de prersonalidad propia.',
        servicios1:'Diseño de logo y manual de marca',
        servicios2:'Creatividad',
        servicios3:'Packaging'
    },
    {
        icon: <FaLaptopCode />,
        title: 'Desarrollo WEB',
        description: 'Tu página web es tu ventana al mundo',
        servicios1:'Diseño web y responsive',
        servicios2:'Ecommerce',
        servicios3:'Usabilidad y arquitectura web'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing Online',
        description: 'Te ayudamos a crear tu plan de marketing online para conseguir tus objetivos:.',
        servicios1:'Generación de contenido',
        servicios2:'Email marketing',
        servicios3:'Posicionamiento web SEO'
    },
    {
        icon: <FiSmile />,
        title: 'Comunicación',
        description: 'Te ayudamos a crear tu plan de marketing online para conseguir tus objetivos:.',
        servicios1:'Generación de contenido',
        servicios2:'Email marketing',
        servicios3:'Posicionamiento web SEO'
    },
    {
        icon: <FaPencilAlt />,
        title: 'Diseño',
        description: 'Nos gusta el diseño simple, conceptual y minimalista para comunicar grandes ideas.',
        servicios1:'Diseño a medida',
        servicios2:'Diseño de banners',
        servicios3:'Material promocional' 
    },
    {
        icon: <FiTv />,
        title: 'Campañas',
        description: 'Optimización de palabras clave y publicidad online.',
        servicios1:'Publicidad en redes sociales',
        servicios2:'Adwords',
        servicios3:'Afiliación' 

    },
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Servicios' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Servicios'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Agencia Digital</h2>
                                    <p>Somos una agencia de marketing  con  servicio completo: estrategías multicanal integradas, imagen de marca, desarrollo web,  e-commerce, gestión de redes sociales y posicionamiento web. Nos gusta mantener relaciones saludables y felices con nuestros clientes.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                                <ul>
                                                    <li>{val.servicios1}</li>
                                                    <li>{val.servicios2}</li>
                                                    <li>{val.servicios3}</li>

                                                </ul>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                    {/* Start Brand Area */}
                    <div className="rn-brand-area brand-separation pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;