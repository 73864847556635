import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'Diseño, Desarrollo Web, Comunicación',
        title: 'Mirave',
        url:'https://mirave.es/'
    },
    {
        image: 'image-2',
        category: 'Diseño, Desarrollo Web, Comunicación',
        title: 'E-koala',
        url:'https://e-koala.com/'
    },
    {
        image: 'image-3',
        category: 'Desarrollo Web',
        title: 'My Elder',
        url:'http://myelder.com/'
    },
    {
        image: 'image-4',
        category: 'Desarrollo Web',
        title: 'Mamita',
        url:'https://www.mamitabotanical.com/'
    },
    {
        image: 'image-5',
        category: 'Diseño, Desarrollo Web, Comunicación',
        title: ' Lara Grill',
        url:'http://laragrill.es/'
    
    },
    {
        image: 'image-6',
        category: 'Desarrollo Web',
        title: 'MSS',
        url:'https://www.mss.es/'
    }
]

class Portfolio extends Component{
    render(){
        let title = 'Portfolio',
        description = 'Conoce el trabajo que realizamos con mucho amor. ¡Cuidamos hasta el último detalle!';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                {/* <a className="rn-btn" href= "/portfolio-details">Más info</a> */}
                                                <a className="rn-btn" href={`${value.url}`} target="_blank">Más info</a>                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;